<template>
    <GlobalBannerEffect />
    <div class="py-12 lg:py-24">
        <div class="container">
            <h2 class="text-blacks-100 text-2xl sm:text-35px lg:text-5xl font-medium !leading-[133.333%] mb-5">
                <v-text option_key="gallery_idea_title" />
            </h2>
            <div
                class="max-w-[957px] text-base lg:text-[18px] !leading-[144.444%] font-medium text-blacks-100 mb-6 lg:mb-12"
            >
                <v-text option_key="gallery_idea_subtitle" />
            </div>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-12 mb-6 lg:mb-12">
                <nuxt-link :to="item?.url" v-for="item in listGalleries" class="">
                    <div class="relative h-[400px] xl:h-[373px] w-full mb-4 lg:mb-8 overflow-hidden group">
                        <NuxtImg
                            :src="getThumbnail(item?.thumbnail, 560, true)"
                            width="560"
                            sizes="sm:100vw md:50vw lg:560px "
                            loading="lazy"
                            :alt="item?.title"
                            :placeholder="[50, 25, 75, 5]"
                            class="w-full h-full object-cover transform duration-250 group-hover:scale-115"
                        />
                        <div
                            @click="isShowVideo = true"
                            v-if="item?.type === 'video'"
                            class="absolute w-full h-full inset-0 bg-overlay duration-300 cursor-pointer flex justify-center items-center"
                        >
                            <img
                                src="/images/home/collection/play-video.svg"
                                alt="icon-play-video"
                                class="w-14 h-14 object-contain"
                            />
                        </div>
                    </div>
                    <div class="text-base lg:text-2xl font-medium text-blacks-100">
                        {{ item?.title }}
                    </div>
                    <div
                        v-if="isShowVideo && item?.type === 'video'"
                        class="fixed w-[90%] h-[400px] sm:w-[80%] sm:h-[90vh] top-1/2 left-1/2 transform -translate-1/2 z-1002"
                    >
                        <video
                            class="w-full h-full aspect-16/9 object-cover"
                            frameborder="0"
                            allowfullscreen
                            allow="autoplay"
                            controls
                        >
                            <source
                                :src="getThumbnail(item?.video, 375, true)"
                                class="w-full h-full"
                                type="video/mp4"
                            />
                            Your browser does not support HTML video.
                        </video>
                    </div>
                </nuxt-link>
            </div>
            <global-pagination
                v-if="dataGallery[0]?.total_page > 1"
                :pages="dataGallery[0]?.total_page"
                :current-page="currentPage"
                class="mt-5"
            />
            <div
                v-if="isShowVideo"
                @click="isShowVideo = false"
                class="fixed w-full h-full inset-0 z-1001 bg-overlay-02"
            ></div>
        </div>
    </div>
</template>
<script setup lang="ts">
const isShowVideo = ref(false)
const currentPage = ref(1)
const dataGallery = ref()
const listGalleries = ref()
const route = useRoute()
const { getInspirationGalleries } = useInspiration()
const getDataGallery = async (page: any) => {
    const data = await Promise.all([getInspirationGalleries({ query: { limit: 12, page: page } })])
        .then((data) => data)
        .catch((e) => {
            console.log(e)
        })
    dataGallery.value = data
    listGalleries.value = dataGallery.value[0]?.galleries
}
await getDataGallery(1)
watch(
    () => route?.query?.page,
    async () => {
        currentPage.value = route?.query?.page || 1
        await getDataGallery(currentPage.value)
    }
)
</script>
